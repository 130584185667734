import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'
import { projects } from '../../data/constants'


const Projects = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState('project');
  return (
    <Container id="portfolio">
      <Wrapper>
        <Title>Portfolio</Title>
        <Desc>
          My academic and professional journey is highlighted by numerous peer-reviewed publications, esteemed projects, certifications, and extensive virtual experience. These achievements underscore my dedication to research excellence, proficiency in project management, and adaptability in leveraging digital platforms for effective remote collaboration.
        </Desc>
        <ToggleButtonGroup >
          {toggle === 'project' ?
            <ToggleButton active value="project" onClick={() => setToggle('project')}>PROJECT</ToggleButton>
            :
            <ToggleButton value="project" onClick={() => setToggle('project')}>PROJECT</ToggleButton>
          }
          <Divider />
          {toggle === 'publication' ?
            <ToggleButton active value="publication" onClick={() => setToggle('publication')}>PUBLICATION</ToggleButton>
            :
            <ToggleButton value="publication" onClick={() => setToggle('publication')}>PUBLICATION</ToggleButton>
          }
          <Divider />
          {toggle === 'certification' ?
            <ToggleButton active value="certification" onClick={() => setToggle('certification')}>CERTIFICATION</ToggleButton>
            :
            <ToggleButton value="certification" onClick={() => setToggle('certification')}>CERTIFICATION</ToggleButton>
          }
          <Divider />
          {toggle === 'virtual experience' ?
            <ToggleButton active value="virtual experience" onClick={() => setToggle('virtual experience')}>VIRTUAL EXPERIENCE</ToggleButton>
            :
            <ToggleButton value="virtual experience" onClick={() => setToggle('virtual experience')}>VIRTUAL EXPERIENCE</ToggleButton>
          }
        </ToggleButtonGroup>

        <CardContainer>
          {toggle === 'all' && projects
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          {projects
            .filter((item) => item.category === toggle)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Projects